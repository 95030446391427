<template>
	<ConsentCollectionLayout
		title="Consent Collection Widgets"
		caption="Add edit and manage your consent collection widgets"
	>
		<!-- <template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template> -->
		<template #content>
			<ConsentCollectionWidgetsTab
				:consent-collection-widgets="consentCollectionWidgets"
				:user-full-permissions="userFullPermissions"
				:user-has-brand="userHasBrand"
				@load="loadConsentCollectionWidgets"
			/>
			<!-- <TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<ConsentCollectionWidgetsTab
						:consent-collection-widgets="consentCollectionWidgets"
						:user-full-permissions="userFullPermissions"
						:user-has-brand="userHasBrand"
						@load="loadConsentCollectionWidgets"
					/>
				</template>
				<template #1>
					translations
				</template>
			</TabItems> -->
		</template>
	</ConsentCollectionLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ConsentCollectionLayout from '../consent-collection-layout.vue'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import ConsentCollectionWidgetsTab from './consent-collection-widgets-tab.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import { getConsentCollectionWidgets } from '../../../../../../shared/utils/api/consent-collection-widgets.js'
import { PREF_WIDGETS_AREA_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		ConsentCollectionLayout,
		// Tabs,
		// TabItems,
		ConsentCollectionWidgetsTab
	},
	data () {
		return {
			tabs: [{ title: 'Consent Collection Widgets', slot: '0' }, { title: 'Widget Translations', slot: '1' }],
			tab: '0',
			consentCollectionWidgets: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(PREF_WIDGETS_AREA_FULL_PERMISSIONS)
		}
	},
	created () {
		this.loadConsentCollectionWidgets()
	},
	methods: {
		async loadConsentCollectionWidgets () {
			const { consentCollectionWidgets } = await getConsentCollectionWidgets()
			this.consentCollectionWidgets = consentCollectionWidgets
		},
		userHasBrand (brandId) {
			return brandOptions.value?.filter(({ value }) => value === brandId).length > 0
		}
	}
}
</script>
